
    @import "config.scss";
 
.container {
  position: absolute;
  display: flex;
  top: 85%;
  width: 100%;
  background: var(--color-grey);
  justify-content: space-between;
  padding-inline: 15px;
  border-radius: 5px 5px var(--br-hard) var(--br-hard);
  z-index: var(--z-nav-fixed);
  gap: 1rem;
  margin-top: 0.5rem;
  padding-top: 20px;
  height: 410px;
}
